.welcome {
    position: relative;
    width: 100%;
    height: 100%;
}
.servr-logo {
    width: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.servr-icon {
    width: 10%;
    position: absolute;
    left: 50%;
    bottom: 35%;
    transform: translateX(-50%);
}
.loading-spinner {
    position: absolute;
    left: 50%;
    bottom: 15%;
    margin-left: -1rem;
}
.mrc-logo {
    width: 40%;
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translateX(-50%);
}

/* ------------------------------- ANIMATIONS ------------------------------- */
.servr-logo {
    animation: servrLogo 5s forwards;
}
.servr-icon {
    animation: servrIcon 5s forwards;
}
.loading-spinner {
    animation: loadingSpinner 5s forwards;
}
.mrc-logo {
    animation: mrcLogo 5s forwards;
}

/* -------------------------------- KEYFRAMES ------------------------------- */
@keyframes servrLogo {
    0% {
        opacity: 0;
        margin-top: -7%;
    }
    20% {
        opacity: 1;
        margin-top: 0;
    }
    40% {
        opacity: 1;
        margin-top: 0;
    }
    60% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
 
@keyframes servrIcon {
    0% {
        opacity: 0;
        margin-bottom: 0;
    }
    20% {
        opacity: 0;
        margin-bottom: 0;
    }
    40% {
        opacity: 0;
        margin-bottom: 0;
    }
    60% {
        opacity: 1;
        margin-bottom: 7%;
    }
    80% {
        opacity: 1;
        margin-bottom: 7%;
    }
    100% {
        opacity: 0;
        margin-bottom: 7%;
    }
}
 
@keyframes mrcLogo {
    0% {
        opacity: 0;
        margin-top: -7%;
    }
    20% {
        opacity: 0;
        margin-top: -7%;
    }
    40% {
        opacity: 0;
        margin-top: 0;
    }
    60% {
        opacity: 1;
        margin-top: 7%;
    }
    80% {
        opacity: 1;
        margin-top: 7%;
    }
    100% {
        opacity: 0;
        margin-top: 7%;
    }
}
 
@keyframes loadingSpinner {
    0% {
        opacity: 0;
        transform: translateY(7%);
    }
    20% {
        opacity: 1;
        transform: translateY(0);
    }
    40% {
        opacity: 1;
        transform: translateY(0);
    }
    60% {
        opacity: 1;
        transform: translateY(0);
    }
    80% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(0);
    }
}
 