.menu-banner {
    height: 24rem;
    background-size: cover !important;
    color: white;
    position: relative;
}
.menu-banner-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--brandred);
    /*
    background-color: rgba(0, 0, 0, 0.3);
    */
}
.menu-banner-overlay > img {
    margin-bottom: 2rem;
    width: 8rem;
}
.menu-banner-overlay > h1 {
    font-size: 1.6rem;
    margin-bottom: 2rem;
}

.table-button {
    padding: 0.5rem 1.25rem !important;
}

.food-item {
    display: flex;
    padding: 1rem;
    transition: all .3s;
    text-decoration: none;
}
a.food-item {
    color: black;
}
.food-item + .food-item {
    border-top: 1px solid lightgrey;
}
a.food-item:hover {
    background-color: rgb(240, 240, 240);
}
.food-item-quantity {
    margin-right: 1rem;
    background-color: var(--main);
    color: white;
    text-align: center;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
}
.food-item-quantity-zero {
    margin-right: 1rem;
    color: lightgrey;
    text-align: center;
    height: 1.5rem;
    width: 1.5rem;
}
.food-item-info {
    display: flex;
    justify-content: space-between;
    flex: 1 0 auto;
}
.food-item-name {
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* ------------------------ ANIMATIONS & TRANSITIONS ------------------------ */
.menu-banner {
    animation: 1.5s ease forwards menuFadeIn;
}

@keyframes menuFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100;
    }
}