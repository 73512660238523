.placeholder-info {
    padding: 0.5rem;
    border: 1px solid lightgrey;
    border-radius: 0.5rem;
}
.placeholder-info.date {
    width: 3rem;
    text-align: center;
    margin-right: 0.5rem;
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.show ~ .card-header {
    background-color: red;
}
.down-arrow {
    height: 1rem;
    width: 1rem;
}
.payment-icons > img {
    height: 1rem;
    margin-left: 2rem;
}