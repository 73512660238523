.modal-content {
    text-align: center;
}
.modal-title {
    flex: 1 0 auto;
}
.modal-footer {
    justify-content: center !important;
}
.modal-body + .modal-body {
    padding-top: 0;
}
.modal-body + h5 {
    padding-top: 1rem;
    border-top: 1px solid #dee2e6;
}

.MuiToggleButtonGroup-root > button > span {
    pointer-events: none;
}

/* ------------------------------- ORDER BLOCK ------------------------------ */
.order-progress {
    width: 50%;
    text-align: center;
}

/* ------------------------------- STAFF TILE ------------------------------- */
.staff-tile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.staff-image {
    height: 5rem;
    width: 5rem;
    border-radius: 0.5rem;
    margin-right: 1rem;
    object-fit: cover;
}
.staff-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.staff-info > p {
    margin: 0;
}
.staff-tips {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 200px;
    margin: 0.5rem;
}

/* --------------------------------- BILLING -------------------------------- */
.total-bill {
    text-align: center;
    flex: 1 0 100%;
    margin-bottom: 0.75rem !important;
    font-weight: 700;
}

/* ------------------------------- ANIMATIONS ------------------------------- */
.table-order {
    opacity: 0;
    animation: 1s ease forwards fadeDown;
}
.table-order:nth-child(2n) {
    opacity: 0;
    animation: 1s ease 0.2s forwards fadeDown;
}
.table-order:nth-child(3n) {
    opacity: 0;
    animation: 1s ease 0.4s forwards fadeDown;
}