.menuItem-name {
    align-items: center;
    justify-content: space-around;
    text-align: center;
}
.menuItem-name > h1 {
    font-size: 1.5rem !important;
}
.menuItem-info {
    margin: 0 1rem;
    padding: 1rem;
    border-radius: 0.25rem;
    overflow: hidden;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
}
.menuItem-info > p {
    margin: 1rem 0 0;
}
.menuItem-image {
    height: 0;
    padding-bottom: 66%;
    overflow: hidden;
    background-color: rgb(240, 240, 240);
    position: relative;
}
.menuItem-image > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
}
.menuItem-counter {
    padding: 2rem 1rem;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
}
.menuItem-counter > img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    transition: all .2s;
}
.menuItem-counter > img:hover {
    opacity: 0.5;
}

.menuItem-counter > h2 {
    padding: 1rem 1.5rem;
    border: 1px solid lightgrey;
    border-radius: 0.25rem;
}
.menuItem-confirm {
    text-align: center;
}

img {
    max-width: 100%;
}

.inline-icon {
    height: 1rem;
    margin-right: 0.5rem;
    align-self: center;
}

/* ------------------------------- ANIMATIONS ------------------------------- */
.menuItem-amount {
    opacity: 0;
    animation: 1s ease forwards fadeIn;
}

.left-arrow {
    opacity: 0;
    animation: 1s ease 0.3s forwards slideInLeft;
}

.right-arrow {
    opacity: 0;
    animation: 1s ease 0.3s forwards slideInRight;
}