/* --------------------------------- VARS --------------------------------- */
:root {
  --main: #0bb493;
  --mainlight: #00ff88;
  --maindark: #002b03;

  --brandred: #BD1F2E;
  --green: #28a745;
}

/* ------------------------------ ROOT and APP ------------------------------ */

#root {
  display: flex;
  justify-content: center;
  font-size: 15px !important;
}

.App {
  max-width: 450px;
  width: 100%;
  min-height: 100vh;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  position: relative;
}

/* ---------------------------------- FONTS --------------------------------- */
h1, .h1 {
  font-weight: 700 !important;
}
h2, .h2 {
  font-weight: 700 !important;
  font-size: 1.9rem !important;
}
h3, .h3 {
  font-weight: 700 !important;
}
h4, .h4 {
  font-weight: 700 !important;
}
h5, .h5 {
  font-weight: 700 !important;
}
h6, .h6 {
  font-weight: 600 !important;
}
.semibold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}

/* ---------------------------- STRUCTURAL LAYOUT --------------------------- */
.full-width {
  width: 100%;
}

.header {
  padding: 2rem 1rem;
  margin-bottom: 1rem;
  background-color: rgb(248, 248, 248);
}
.subheading {
  padding: 1rem;
  margin-top: 1rem;
}
.subheading>* {
  margin: 0;
}
.bubble-group {
  margin: 1rem;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
}
.bubble-group > h5 {
  margin: 0;
}
.goBack {
  cursor: pointer;
  transition: all .3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--main);
  padding: 0.5rem;
  text-align: center;
  border-radius: 0.5rem;
}
.goBack.arrow {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}
.goBack > .menu-icon {
  width: 2rem;
}
.goBack:hover {
  background-color: rgb(234, 234, 234);
}

.btn {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.3);
}

input {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem !important;
  border: 1px solid lightgrey;
}

/* --------------------------- PADDING AND MARGINS -------------------------- */
section {
  margin-bottom: 100px;
  min-height: 100vh;
}
.py1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px1 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.p1 {
  padding: 1rem;
}
.order-heading {
  padding: 1rem 1.5rem;
}
.order-heading > * {
  margin: 0;
}

/* --------------------------------- COLORS --------------------------------- */
.grey-bg {
  background-color: rgba(0,0,0,.05)
}
.border-bottom {
  border-bottom: 1px solid lightgrey;
}
.highlight-bg {
  background-color: rgba(11,180,147, 0.075);
}
.color-main {
  color: var(--main);
}
.color-main-dark {
  color: var(--maindark)
}
.color-main-light {
  color: var(--mainlight);
}

/* ---------------------------- HEADING BRANDING ---------------------------- */
.servr-branding {
  position: absolute;
  width: 3rem;
  height: 3rem;
  padding: 0.5rem;
  background-color: white;
  border-radius: 50%;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 1.5rem);
  text-align: center;
}
.servr-branding>img {
  height: 2rem;
}

/* TEXT ALIGNMENTS */ 
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

/* --------------------------------- FLEXBOX -------------------------------- */
.flex { display: flex; }
.align-start { align-items: flex-start; }
.align-end { align-items: flex-end; }
.align-center { align-items: center; }
.justify-start { justify-content: flex-start; }
.justify-end { justify-content: flex-end; }
.justify-center { justify-content: center; }
.justify-between { justify-content: space-between; }

/* ROW & COL styling */
[class*="col-"], .col {
  display: flex;
  align-items: center;
}

/* ------------------- Styling for the fixed footer button ------------------ */
.fixed-footer {
  padding: 1rem 0.75rem;
  position: fixed;
  max-width: 448px;
  width: 100%;
  bottom: 0;
}
.fixed-footer>button {
  width: 100%;
  padding: 0;
}
.fixed-footer>button>* {
    display: flex;
    justify-content: space-between;
    color: inherit;
    padding: 1.25rem 1rem;
}

/* ---------------------------- HOVER ANIMATIONS ---------------------------- */
.accordion .card-header {
  transition: all 0.3s;
}
.accordion .card-header:hover {
  opacity: 0.6;
  cursor: pointer;
}

/* -------------------------- RANDOM AD HOC STYLING ------------------------- */
a:hover {
  color: inherit !important;
  text-decoration: none !important;
}
.MuiToggleButton-root {
  padding: 0.5rem !important;
}

/* --------------------------- REUSABLE ANIMATIONS -------------------------- */
.fadeIn {
  opacity: 0;
  animation: 1s ease forwards fadeIn;
}

.header {
  opacity: 0;
  animation: 1.2s ease forwards fadeDown;
}

.subheading {
  opacity: 0;
  animation: 1.2s ease forwards fadeIn;
}

.bubble-group {
  opacity: 0;
  animation: 0.6s ease forwards slideInLeft;
}

button {
  opacity: 0;
  animation: 1s ease forwards fadeIn;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 100;
  }
}

@keyframes fadeUp {
  0% {
      opacity: 0;
      transform: translateY(1rem);
  }
  100% {
      opacity: 100;
      transform: translateY(0);
  }
}

@keyframes fadeDown {
  0% {
      opacity: 0;
      transform: translateY(-1rem);
  }
  100% {
      opacity: 100;
      transform: translateY(0);
  }
}

@keyframes slideInLeft {
  0% {
      opacity: 0;
      transform: translateX(-0.5rem);
  }
  100% {
      opacity: 100;
      transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
      opacity: 0;
      transform: translateX(0.5rem);
  }
  100% {
      opacity: 100;
      transform: translateX(0);
  }
}