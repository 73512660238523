.username, .password {
    margin-bottom: 1rem;
}
input {
    display: block;
    width: 100%;
    height: 3rem;
    margin: 1rem 0;
}
.login-form {
    padding-left: 3rem;
    padding-right: 3rem;
}
.login-buttons {
    display: flex;
    justify-content: center;
}
.login-buttons > * {
    padding: 1rem;
    border-radius: 0.5rem;
    width: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
}
.login {
    background-color: lightgreen;
}
.signup {
    background-color: lightpink;
}
.social-logins {
    display: flex;
    justify-content: space-around;
}
.social-logins .logo {
    transition: all 0.3s;
}
.social-logins .logo:hover {
    cursor: pointer;
    opacity: 0.6;
}
.facebook {
    height: 3rem;
    width: 3rem;
}
.google {
    height: 3rem;
    width: 3rem;
}
.guest {
    height: 4rem;
    background: lightgreen;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 0.5rem;
}